.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.app-container {
  display: flex;
}

.sidebar {
  width: 30%;
  border-right: 1px solid #ccc;
  padding: 1rem;
  overflow-y: auto;
  height: 100vh;
  position: relative;
}

.details {
  width: 70%;
  padding: 1rem;
}

.filter-container {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.filter-group {
  flex-grow: 1;
}

.filter-group label {
  display: flex;
  align-items: center;
}

.filter-group select {
  margin-left: 0.5rem;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.toggle-hidden-button {
  margin-left: 0.5rem;
  background-color: #17a2b8;
  padding: 0.5rem;
  font-size: 0.8rem;
}

.aircraft-list {
  list-style: none;
  padding: 0;
}

.aircraft-list li {
  cursor: pointer;
  padding: 0.5rem;
  border-bottom: 1px solid #ccc;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.aircraft-list li:hover {
  background-color: #f0f0f0;
}

.aircraft-info {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.aircraft-details {
  display: flex;
  justify-content: space-between;
  font-size: 0.8rem;
  color: #555;
  width: 100%;
}

.aircraft-variant {
  font-style: italic;
}

.aircraft-date {
  font-style: italic;
  margin-left: auto;
}

.aircraft-icons {
  display: flex;
  gap: 5px;
}

.aircraft-icon {
  width: 20px;
  height: 20px;
  display: inline-block;
  text-align: center;
}

button {
  display: inline-block;
  margin: 0.5rem 0;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s;
}

button:hover {
  opacity: 0.8;
}

button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

button {
  background-color: #007bff;
}

.signout-button {
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: #dc3545;
  padding: 0.2rem 0.5rem;
  font-size: 0.8rem;
}

.aircraft-detail {
  text-align: left;
}

.aircraft-detail h2 {
  margin-bottom: 1rem;
  display: flex;
  gap: 10px;
  align-items: center;
}

.aircraft-detail h2 input {
  font-size: 1.5rem;
  width: auto;
}

.aircraft-detail p {
  margin: 0.5rem 0;
}

.form-group {
  margin-bottom: 1rem;
}

.form-group label {
  display: block;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.form-group textarea {
  resize: vertical;
}

.button-group {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 1rem;
}

.save-button {
  background-color: #007bff;
}

.duplicate-button {
  background-color: #6c757d;
}

.delete-button {
  background-color: #dc3545;
}

.hide-button {
  background-color: #ffc107;
}

.selected-button {
  background-color: #28a745;
}

.test-button {
  background-color: #17a2b8;
}

.confirmation {
  color: green;
  font-weight: bold;
  margin-top: 1rem;
}
